import { Controller } from 'stimulus'
import { debounce, sleep } from '../utilities'

export default class extends Controller {
  static get targets() {
    return [
      'stickyNav',
      'stickyDivider',
      'background',
      'container',
      'form',
      'buttons',
      'submitButton',
      'loading',
      'resetButton',
      'sortOptions',
      'cta',
      'offScreenTrigger'
    ]
  }

  initialize() {
    this.stickyNavActivated = false
    this.onScroll()
    if (this.hasFormTarget) {
      this.applyFiltersFromUrl()
      if (this.anyFiltersApplied()) {
       this.enableResetButton()
      }
    }

    this.debouncedFilterChange = debounce(this.filterChange, 250)
  }

  connect() {
    window.addEventListener('scroll', this.onScroll.bind(this))
    window.addEventListener('infinite-scroll-load', this.onContentLoaded.bind(this))
  }

  disconnect() {
    window.removeEventListener('scroll', this.onScroll.bind(this))
    window.removeEventListener('infinite-scroll-load', this.onContentLoaded.bind(this))
  }

  onScroll() {
    const toggle = this.offScreenTriggerTarget.getBoundingClientRect().top <= 0;
    if (toggle !== this.stickyNavActivated) {
      this.activateStickyNav(toggle)
    }
  }

  async activateStickyNav(toggle) {
    if (toggle && !this.stickyNavActivated) {
      this.stickyNavTarget.classList.remove('pointer-events-none')
      this.stickyNavTarget.classList.remove('opacity-0')
      this.stickyNavActivated = true
    } else if (!toggle && this.stickyNavActivated) {
      this.stickyNavTarget.classList.add('opacity-0')
      this.stickyNavTarget.classList.add('pointer-events-none')
      this.stickyNavActivated = false
    }
  }

  onContentLoaded(e) {
    if (this.hasLoadingTarget) {
      this.loadingTarget.classList.add('opacity-0')
    }
    const count = e.detail.total
    if (this.hasSubmitButtonTarget) {
      if (count !== undefined || count !== null) {
        this.submitButtonTarget.children[1].innerHTML = `See ${count} trip${count === '1' ? '' : 's'}`
      } else {
        this.submitButtonTarget.children[1].innerHTML = `See trips`
      }
    }
    this.resetting = false
  }

  openSlideout() {
    document.documentElement.style.overflow = 'hidden';
    document.body.scroll = 'no';
    this.formTarget.classList.add('-translate-x-full')
    this.buttonsTarget.classList.add('-translate-x-full')
    this.backgroundTarget.classList.add('opacity-80')
    this.backgroundTarget.classList.remove('opacity-0')
    setTimeout(() => this.containerTarget.classList.remove('pointer-events-none'), 500)
  }

  closeSlideout(e) {
    e.preventDefault()
    e.stopPropagation()
    document.documentElement.style.overflow = 'scroll';
    document.body.scroll = 'yes';
    this.containerTarget.classList.add('pointer-events-none')
    this.formTarget.classList.remove('-translate-x-full')
    this.buttonsTarget.classList.remove('-translate-x-full')
    this.backgroundTarget.classList.remove('opacity-80')
    this.backgroundTarget.classList.add('opacity-0')
  }

  applyFiltersFromUrl() {
    const params = new URLSearchParams(window.location.search)
    this.inputs().forEach((input) => {
      if (input.type === 'checkbox' || input.type === 'radio') {
        params.getAll(input.name).forEach((value) => {
          if (input.value === value) {
            input.checked = input.value === value
          }
        })
      } else if (params.has(input.id) && params.get(input.id) !== null) {
        input.value = params.get(input.id)
        input.dispatchEvent(new CustomEvent('change', { value: input.value }));
      }
    })
  }

  filterChange() {
    const url = this.urlWithFilters()
    window.history.replaceState({}, '', url)
    if (this.hasLoadingTarget) {
      this.loadingTarget.classList.remove('opacity-0')
    }
    window.dispatchEvent(new CustomEvent('filter-change'))
  }

  updateFilters(e) {
    if (this.resetting) { return }
    const imageSection = document.querySelector("[data-target='destination.image-section']")
    if (this.anyFiltersApplied()) {
      this.enableResetButton()
      imageSection.style.display = 'none'
    } else {
      this.disableResetButton()
      imageSection.style.display = ''
    }
    this.debouncedFilterChange()
  }

  enableResetButton() {
    this.resetButtonTarget.disabled = false
  }

  disableResetButton() {
    this.resetButtonTarget.disabled = true
  }

  resetFilters(e) {
    this.resetting = true
    e.preventDefault()
    e.stopPropagation()
    this.inputs().forEach((input) => {
      if (input.type === 'checkbox' || input.type === 'radio') {
        input.checked = false
      } else {
        input.value = null
        input.dispatchEvent(new Event('change', { value: null }));
      }
    })
    this.disableResetButton()
    this.filterChange()
  }

  inputs() {
    return this.formTarget.querySelectorAll("input[name^=filter-], input[name^=range-filter-]");
  }

  urlWithFilters() {
    const formData = new FormData(this.containerTarget);
    const data = []
    for (const [key, value] of formData) {
      data.push([key, value]);
    }
    const searchParams = new URLSearchParams(data)
    const url = new URL(window.location.href.split(/[?#]/)[0])
    if (data.length > 0) {
      return `${url.toString()}?${searchParams.toString()}`
    }
    return url.toString()
  }

  anyFiltersApplied() {
    for (const input of this.inputs()) {
      if ((input.type === 'checkbox' || input.type === 'radio') && input.checked) {
        return true
      } else if (input.dataset.defaultValue && input.dataset.defaultValue !== input.value) {
        return true
      }
    }
    return false
  }
}
